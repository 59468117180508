import * as React from "react"
import PropTypes from "prop-types"

const EmailLink = ({ email }) => {
  if (!email) return null

  return (
    <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
      {email}
    </a>
  )
}

EmailLink.propTypes = {
  email: PropTypes.string,
}

export default EmailLink
