import * as React from "react"

const Logo = () => {
  return (
    <div className="flex items-center space-x-1 lg:space-x-2 font-bold leading-tight">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 720 720"
        role="img"
        aria-label="Matthew Raw 'MR' logo"
        className="h-8 w-8 lg:h-10 lg:w-10"
      >
        <g fill="#000" fillRule="nonzero">
          <path d="M360 0C161.496 0 0 161.496 0 360s161.496 360 360 360 360-161.496 360-360S558.504 0 360 0Zm0 672.552C187.632 672.552 47.376 532.368 47.376 360 47.376 187.632 187.632 47.376 360 47.376c172.368 0 312.552 140.256 312.552 312.552 0 172.296-140.184 312.624-312.552 312.624Z" />
          <path d="M234.792 329.976 185.76 209.592h-51.768V387.72h37.656V271.152L218.16 387.72h32.184l46.512-118.08v118.08h38.952V209.592h-54.504l-46.512 120.384Zm351.216-31.248c0-50.04-35.424-89.64-92.52-89.64H380.016V504.72h50.4V387.936h36.72l62.208 116.784h56.664l-66.672-121.752c41.256-10.008 66.672-42.048 66.672-84.24ZM430.416 343.8v-90.504H484.2c30.888 0 50.904 17.928 50.904 45.504 0 27.144-20.016 45.072-50.904 45.072h-53.784v-.072Z" />
          <circle cx="234.936" cy="479.952" r="30.888" />
        </g>
      </svg>
      <div>
        <p>Matthew Raw</p>
        <p>Works in Clay</p>
      </div>
    </div>
  )
}

export default Logo
