import * as React from "react"
import PropTypes from "prop-types"

import useGridContainerHeight from "../hooks/useGridContainerHeight"

const GridRowAutoHeight = ({ horizontal, children }) => {
  const container = React.useRef(null)
  const containerHeight = useGridContainerHeight(container, horizontal)
  const containerStyle = { height: containerHeight }

  return (
    <div ref={container} style={containerStyle}>
      {children}
    </div>
  )
}

GridRowAutoHeight.propTypes = {
  horizontal: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default GridRowAutoHeight
