import * as React from "react"

import Grid from "./Grid"
import * as styles from "./DosCredit.module.css"

const DosCredit = () => {
  const grid = {
    horizontal: "a",
    vertical: "a",
  }

  return (
    <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
      <div className="h-row flex justify-center">
        <div className="w-22/24 lg:w-23/24 lg:pb-4 flex justify-center items-center lg:justify-end lg:items-end">
          <p className="text-xs leading-snug lg:text-sm lg:leading-snug">
            Site by{" "}
            <a
              href="https://dos.studio"
              target="_blank"
              rel="noreferrer"
              className={styles.dosCredit}
            >
              <span className={styles.dosCreditText}>Dos</span>
            </a>
          </p>
        </div>
      </div>
    </Grid>
  )
}

export default DosCredit
