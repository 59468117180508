import * as React from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import NavMenu from "./NavMenu"
import InstagramLink from "./InstagramLink"

const MobileMenu = () => {
  const bodyScrollLockTarget = React.useRef()

  React.useEffect(() => {
    // Get element to pass to body-scroll-lock
    const el = bodyScrollLockTarget.current

    // Enable body-scroll-lock on mount
    disableBodyScroll(el)

    // Disable body-scroll-lock on unmount
    return () => {
      enableBodyScroll(el)
    }
  })

  return (
    <nav
      ref={bodyScrollLockTarget}
      id="mobile-menu"
      className="fixed z-10 inset-0 bg-white flex justify-center pt-row-2 py-6 pointer-events-auto"
    >
      <div className="w-10/12 flex flex-col justify-between">
        <NavMenu />
        <InstagramLink />
      </div>
    </nav>
  )
}

export default MobileMenu
