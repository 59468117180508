import * as React from "react"
import PropTypes from "prop-types"
import * as styles from "./MobileMenuButton.module.css"

const MobileMenuButton = ({ onClick, active }) => {
  return (
    <button
      className={`lg:hidden hamburger hamburger--squeeze ${styles.hamburger} ${
        active ? "is-active" : ""
      }`}
      onClick={onClick}
      type="button"
      aria-haspopup="menu"
      aria-controls="mobile-menu"
      aria-expanded={active}
    >
      <span className={`hamburger-box ${styles.hamburgerBox}`}>
        <span className={`hamburger-inner ${styles.hamburgerInner}`} />
      </span>
      <span className="sr-only">{active ? "Close menu" : "Open menu"}</span>
    </button>
  )
}

MobileMenuButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export default MobileMenuButton
