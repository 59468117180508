import * as React from "react"
import PropTypes from "prop-types"
import * as styles from "./TextContent.module.css"

const TextContent = ({ children }) => {
  return <div className={styles.text}>{children}</div>
}

TextContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TextContent
