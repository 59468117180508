import * as React from "react"
import PropTypes from "prop-types"
import * as styles from "./Grid.module.css"

const Grid = ({ horizontal, vertical, children }) => {
  const horizontalClass =
    horizontal === "b" ? styles.glHorizontalB : styles.glHorizontalA
  const verticalClass =
    vertical === "b" ? styles.glVerticalB : styles.glVerticalA

  return (
    <div className={`${styles.gridLines} ${horizontalClass} ${verticalClass}`}>
      {children}
    </div>
  )
}

export default Grid

Grid.propTypes = {
  horizontal: PropTypes.string,
  vertical: PropTypes.string,
  children: PropTypes.node.isRequired,
}
