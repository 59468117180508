import * as React from "react"
import PropTypes from "prop-types"

const PhoneLink = ({ phone }) => {
  if (!phone) return null
  const phoneNumberNoSpaces = phone.replace(/\s+/g, "")

  return (
    <a href={`tel:${phoneNumberNoSpaces}`} target="_blank" rel="noreferrer">
      {phone}
    </a>
  )
}

PhoneLink.propTypes = {
  phone: PropTypes.string,
}

export default PhoneLink
