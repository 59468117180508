import * as React from "react"
import { Link } from "gatsby"
import { debounce } from "lodash"

import Grid from "./Grid"
import Logo from "./Logo"
import NavMenu from "./NavMenu"
import InstagramLink from "./InstagramLink"
import MobileMenu from "./MobileMenu"
import MobileMenuButton from "./MobileMenuButton"

const Header = () => {
  const grid = {
    horizontal: "a",
    vertical: "b",
  }

  // Handle state for mobile menu
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

  React.useEffect(() => {
    // Add listener to ensure mobile menu is disabled for large layout
    const checkWindowSize = () => {
      if (window.innerWidth >= 1024) {
        setMobileMenuOpen(false)
      }
    }
    const checkWindowSizeDebounced = debounce(checkWindowSize, 50)

    // Run function on window resize
    window.addEventListener("resize", checkWindowSizeDebounced)

    // Remove listener on unmount
    return () => {
      window.removeEventListener("resize", checkWindowSizeDebounced)
    }
  }, [])

  return (
    <div className="relative z-10 pointer-events-none">
      <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
        <div className="h-row" />
      </Grid>
      <header className="fixed top-0 left-0 z-50 h-row w-full flex justify-center">
        <div className="w-22/24 lg:w-23/24 flex items-center sm:items-start lg:items-center xl:items-start sm:pt-3 lg:pt-0 xl:pt-3">
          <div className="w-full flex items-center justify-between">
            <div className="pointer-events-auto">
              <Link to="/" rel="home">
                <Logo />
              </Link>
            </div>
            <nav className="pointer-events-auto">
              <MobileMenuButton
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen)
                }}
                active={mobileMenuOpen}
              />
              <div className="hidden lg:flex items-center space-x-3">
                <NavMenu />
                <InstagramLink />
              </div>
            </nav>
          </div>
        </div>
      </header>
      {mobileMenuOpen && <MobileMenu />}
    </div>
  )
}

export default Header
