import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { RichText } from "prismic-reactjs"
import * as styles from "./NavMenu.module.css"

const NavMenu = () => {
  const staticData = useStaticQuery(query)
  const { data } = useMergePrismicPreviewData(staticData)
  const { prismicMenu } = data
  const { main_menu } = prismicMenu.data

  // Check if on project page and highlight projects link
  const isProjectPage = !!(
    typeof window !== "undefined" &&
    window.location.pathname.includes("/projects/")
  )

  return (
    <ul role="menu" className={styles.navMenu}>
      {main_menu.map((navItem) => {
        return (
          navItem.link.url &&
          navItem.label.raw.length > 0 && (
            <li key={navItem.link.id}>
              <Link
                to={navItem.link.url}
                role="menuitem"
                className={
                  isProjectPage && navItem.link.url === "/"
                    ? styles.isActive
                    : ""
                }
                rel={navItem.link.url === "/" ? "home" : ""}
              >
                {RichText.asText(navItem.label.raw)}
              </Link>
            </li>
          )
        )
      })}
    </ul>
  )
}

const query = graphql`
  query Menu {
    prismicMenu {
      _previewable
      data {
        main_menu {
          label {
            raw
          }
          link {
            url
            id
          }
        }
      }
    }
  }
`

export default NavMenu
