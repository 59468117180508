import * as React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"

import Header from "./Header"
import Footer from "./Footer"
import DosCredit from "./DosCredit"
import useSiteData from "../hooks/useSiteData"
import hexToRGB from "../utils/hexToRGB"

const Layout = ({ children }) => {
  const isAboutPage = !!(
    typeof window !== "undefined" && window.location.pathname === "/about"
  )

  const {
    horizontal_line_color,
    horizontal_line_opacity,
    vertical_line_color,
    vertical_line_opacity,
  } = useSiteData()

  // Set custom grid line colors
  let GridColor = null
  if (
    horizontal_line_color &&
    horizontal_line_opacity &&
    vertical_line_color &&
    vertical_line_opacity
  ) {
    GridColor = createGlobalStyle`
    :root {
      --color-horizontal: rgba(${hexToRGB(
        horizontal_line_color
      )}, ${horizontal_line_opacity});
      --color-vertical: rgba(${hexToRGB(
        vertical_line_color
      )}, ${vertical_line_opacity});
    }
  `
  }

  return (
    <div>
      {GridColor !== null && <GridColor />}
      <div className="font-sans text-black bg-white text-sm lg:text-base leading-snug lg:leading-snug">
        <Header />
        <main>{children}</main>
        <Footer />
        {isAboutPage && <DosCredit />}
      </div>
    </div>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
