import * as React from "react"
import { debounce } from "lodash"

// Ensure height of grid line containers is a multiple of grid row height
const useGridContainerHeight = (refEl, horizontal) => {
  // Return if ssr
  if (typeof window === "undefined") return "auto"

  // Create state and setter
  const [elHeight, setElHeight] = React.useState(null)

  React.useLayoutEffect(() => {
    // Get current element from passed ref
    const el = refEl.current

    // Take grid container element and return new height
    function changeElHeight() {
      // Clone element and set height to auto
      const clone = el.cloneNode(true)
      clone.style.height = "auto"
      // Insert clone into DOM and get height
      el.after(clone)
      const currentHeight = clone.clientHeight
      // Remove clone from DOM
      clone.remove()
      // Set row height based on window width
      const viewportWidth = window.innerWidth
      let rowHeight = 18.75
      if (viewportWidth >= 1024) {
        rowHeight = 12.5
      } else if (viewportWidth >= 640) {
        rowHeight = 12.5
      }
      // and layout type
      if (horizontal === "b") {
        rowHeight = rowHeight / 2
      }
      // Convert vw based row height into pixels
      const pxRowHeight = (viewportWidth / 100) * rowHeight
      // Find remainder to next full row
      const heightDifference = currentHeight % pxRowHeight
      // Set row height
      const newHeight = currentHeight - heightDifference + pxRowHeight

      // Update state
      setElHeight(newHeight)
    }

    // Debounce function for window resize
    const changeElHeightDebounced = debounce(changeElHeight, 50)

    // Run function on mount and window resize
    changeElHeight()
    window.addEventListener("resize", changeElHeightDebounced)

    // Remove listener on unmount
    return () => {
      window.removeEventListener("resize", changeElHeightDebounced)
    }
  }, [])

  return elHeight
}

export default useGridContainerHeight
