import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@gatsbyjs/reach-router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article, titleTemplate }) => {
  const { pathname } = useLocation()
  const { site, prismicSite } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultTitleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const { userDefaultDescription, userDefaultImage } = prismicSite.data
  const userDefault = {
    description: userDefaultDescription.text,
    image: userDefaultImage.url,
  }

  const seo = {
    title: title || defaultTitle,
    titleTemplate: titleTemplate || defaultTitleTemplate,
    description: description || userDefault.description || defaultDescription,
    image: image || userDefault.image || defaultImage,
    url: `${siteUrl}${pathname}`,
  }

  const html = {
    lang: "en-GB",
  }

  return (
    <Helmet
      htmlAttributes={html}
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      image={seo.image}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  titleTemplate: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  titleTemplate: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultTitleTemplate: titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
    prismicSite {
      data {
        userDefaultDescription: site_meta_description {
          text
        }
        userDefaultImage: site_meta_image {
          url
        }
      }
    }
  }
`
