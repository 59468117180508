import * as React from "react"

import useSiteData from "../hooks/useSiteData"
import Grid from "./Grid"
import TextContent from "./TextContent"
import EmailLink from "./EmailLink"
import PhoneLink from "./PhoneLink"

const Footer = () => {
  const { email, phone, copyright } = useSiteData()

  let copyrightMessage = copyright.text
    ? `© ${copyright.text} ${new Date().getFullYear()}`
    : null

  const grid = {
    horizontal: "a",
    vertical: "b",
  }

  return (
    <footer>
      <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
        <div className="flex justify-center">
          <div className="w-22/24 lg:w-23/24 flex flex-wrap">
            <div className="w-full lg:w-1/2 h-row-2 lg:pb-4 flex justify-center items-center lg:justify-start lg:items-end">
              <TextContent>
                {email.text && (
                  <div>
                    <p className="text-center text-base leading-loose lg:leading-relaxed lg:text-left">
                      <EmailLink email={email.text} />
                    </p>
                  </div>
                )}
                {phone.text && (
                  <div>
                    <p className="text-center text-base leading-loose lg:leading-relaxed lg:text-left">
                      <PhoneLink phone={phone.text} />
                    </p>
                  </div>
                )}
              </TextContent>
            </div>
            {copyrightMessage && (
              <div className="w-full lg:w-1/2 h-row lg:h-row-2 lg:pb-4 flex justify-center items-center lg:justify-end lg:items-end">
                <p className="text-center text-xs leading-snug lg:text-right lg:text-sm lg:leading-snug">
                  {copyrightMessage}
                </p>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </footer>
  )
}

export default Footer
