import { useStaticQuery, graphql } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

const useSiteData = () => {
  const staticData = useStaticQuery(query)
  const { data } = useMergePrismicPreviewData(staticData)
  const { prismicSite } = data
  return prismicSite.data
}

const query = graphql`
  query Site {
    prismicSite {
      _previewable
      data {
        logo_line_1 {
          text
        }
        logo_line_2 {
          text
        }
        email {
          text
        }
        phone {
          text
        }
        copyright {
          text
        }
        horizontal_line_color
        horizontal_line_opacity
        vertical_line_color
        vertical_line_opacity
      }
    }
  }
`

export default useSiteData
